$(function() {
    let target = window.location.hash;
    if (target.length && target === '#profile') {
        $("html, body").animate({
            scrollTop: $('#profile').offset().top - 140
        }, 500);
    }
    $('.b64').each(function() {
        $(this).html(b64DecodeUnicode($(this).html())).removeClass('b64');
    });
    $("#languageMenu_showMenu").on("mouseenter", function() {
        if ($("#languageMenu_menuContainer").is(":hidden")) {
            $("#languageMenu_menuContainer").show();
        }
    });
    $("#languageMenu_menuContainer").on("mouseleave", function() {
        if ($("#languageMenu_menuContainer").is(":visible")) {
            $("#languageMenu_menuContainer").hide();
        }
    });
    $(".slidingPopupContainer_left").on("click", function() {
        if ($(".slidingPopupContainer").css("right") != "0px") {
            $(".slidingPopupContainer").stop(true, false).animate({
                right: "0"
            }, "slow");
        } else {
            $(".slidingPopupContainer").stop(true, false).animate({
                right: "-270px"
            }, "slow");
        }
    });
    $('#mobile-menu-btn').on("click", function(e) {
        e.preventDefault();
        $('body').toggleClass('mobile-menu-opened');
        $(this).toggleClass('header__hamburger--active');
        $('#mobile-menu').toggleClass('active');
        if ($('.navigation-v5__container.extra--info').length) {
            $('.navigation-v5__container.extra--info').toggle();
        }
    });
    $('.mobile-menu > ul > li.has-submenu > span').on('click', function(e) {
        e.preventDefault();
        $(this).toggleClass('opened');
        $(this).parent('.has-submenu').toggleClass('opened');
        $(this).next('ul').stop().slideToggle(300, function() {
            if ($(e.target).hasClass('opened')) {
                $('.mobile-menu').animate({
                    scrollTop: $(e.target).closest('.has-submenu').position().top + $('.mobile-menu').scrollTop()
                }, 500);
            }
        });
    });
    $(document).on("focus", ".autoReset", function() {
        if ($(this).val() == $(this).attr('title')) {
            $(this).val("");
            $(this).removeClass('emptyText');
        }
    });
    $(document).on("blur", ".autoReset", function() {
        if ($(this).val() == "") {
            $(this).addClass('emptyText');
            if ($(this).has("[title]")) {
                $(this).val($(this).attr('title'));
            }
        }
    });
});

$(document).ready(function() {
    $('input[name="search_radio_listingType"]').on("change", function() {
        $("#search_listingType").val($(this).val());
        categoryFilter("");
    });
});
$('input[name="search_radio_listingType"]').filter("[value=" + $("#search_listingType").val() + "]").attr('checked', true);

function mobileMenuResize() {
    $(window).width() > 991 && ($("body").remove("mobile-menu-opened"), $("#mobile-menu-btn").remove("header__hamburger--active"), $("#mobile-menu").remove("active"))
}

function topSpace() {
    const o = $(".navigation-v5").height(),
        n = o - 20;
    $(window).width() < 992 && $(".v5-property__container").css("margin-top", n), $(window).width() < 768 && ($(".v5-search-title").css("margin-top", n), $(".v5-property__container").css("margin-top", o))
}

function stickyHeader() {
    $("body").hasClass("mobilePrompt") && ($(window).scrollTop() >= 1 ? $(".navigation-v5, #mobile-menu").addClass("not--top") : $(".navigation-v5, #mobile-menu").removeClass("not--top")), $("body").hasClass("v5-homepage") && ($(window).scrollTop() >= 1 ? $(".navigation-v5, #mobile-menu").removeClass("is--transparent") : $(".navigation-v5, #mobile-menu").addClass("is--transparent"), setTimeout((function() {
        $(".navigation-v5").css("opacity", "1")
    }), 200))
}

function footerAccordion() {
    $("footer .accordion__btn").unbind("click").on("click", (function() {
        $(window).width() <= 991 && ($(this).toggleClass("open"), $(this).next(".accordion__container").stop().slideToggle(150))
    }))
}

function moufaArrow() {
    $(".v5-slider").length && $(".v5-slider").each((function() {
        var o = $(this),
            n = o.find(".v5-slider__main img").height() / 2,
            i = o.find(".owl-nav>div, .owl-buttons>div");
        i.length && (i.css("top", n), o.find(".owl-nav, .owl-buttons").css("opacity", "1"))
    }))
}

$(document).ready((function() {
    stickyHeader(), footerAccordion(), topSpace()
})), $(window).on("load", (function() {
    moufaArrow()
})), $(window).on("resize", (function() {
    mobileMenuResize(), footerAccordion(), moufaArrow(), topSpace()
})), $(window).on("scroll", (function() {
    stickyHeader()
}));